<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-12 20:36:16
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-30 14:39:43
 * @FilePath: /siana_admin/src/views/system/agent/index.vue
-->
<template>
  <el-card>
    <div slot="header">
      <h2>代理配置
      </h2>
    </div>
    <el-form ref="ruleForm"
      :model="form"
      :rules="rules"
      label-width="180px"
      :label-position="phone?'top':'right'"
      class="demo-ruleForm">
      <el-form-item
        label="是否开启代理"
        prop="isOpenProxy">
        <el-select
          v-model="form.isOpenProxy"
          :disabled="disabled"
          style="width:100%;max-width: 600px;"
          placeholder="请选择是否开启代理">
          <el-option
            label="关闭"
            :value="0" />
          <el-option
            label="开启"
            :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item
        label="代理ip/域名"
        prop="proxyIp">
        <el-input
          v-model="form.proxyIp"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>

      <el-form-item
        label="代理端口"
        prop="proxyPort">
        <el-input
          v-model="form.proxyPort"
          :disabled="disabled"
          style="width:100%;max-width: 600px;" />
      </el-form-item>
      <el-form-item
        v-if="!disabled">
        <el-button
          type="primary"
          @click="handleSubmit()">保存</el-button>
        <el-button
          @click="handleCancel()">取消</el-button>
      </el-form-item>
      <el-form-item v-else>
        <el-button
          type="primary"
          @click="disabled = !disabled">去编辑</el-button>

      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      phone: false,
      form: {
        isOpenProxy: 0, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
        proxyIp: '', // gpt请求地址
        proxyPort: '' // 默认注册次数
      },
      disabled: true,
      rules: {
        isOpenProxy: [{ required: false, message: '请选择是否开启代理', trigger: 'blur' }],
        proxyIp: [{ required: false, message: '请输入', trigger: 'blur' }],
        proxyPort: [{ required: false, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
    this.getData()
  },
  methods: {
    getData() {
      this.$https('SYSTEMSETTING', {}).then(res => {
        this.form = {
          id: res.data.id,
          isOpenProxy: res.data.isOpenProxy, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
          proxyIp: res.data.proxyIp, // gpt请求地址
          proxyPort: res.data.proxyPort // stablediffusion lora地址
        }
      })
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$https('SYSTEMEDIT', this.form).then(res => {
            if (res.status == 200) {
              this.$message.success('编辑成功！')
              this.disabled = true
            }
          })
        }
      })
    },
    handleCancel() {
      this.form = {
        id: '',
        isOpenProxy: 0, // 注册模式  1 账号密码 2 邮箱注册 3 公众号
        proxyIp: '', // gpt请求地址
        proxyPort: ''
      }
      this.getData()
      this.disabled = true
    }
  }
}
</script>

<style>
</style>
